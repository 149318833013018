<template>
  <div class="ap-dark-gray--text" style="width: 300px">
    <div v-if="text" class="font-weight-medium">{{ text }}</div>
    <v-progress-linear color="primary" indeterminate rounded height="4" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
