<template>
  <div>
    <v-snackbar
      v-for="(message, index) in messages"
      :key="message.id"
      :value="true"
      top
      right
      timeout="3000"
      :color="message.backgroundColor"
      :style="{ 'margin-top': 64 * index + 'px' }"
      @input="$emit('remove', message.id)"
    >
      <div>
        <div class="d-flex align-center font-weight-medium">
          <v-icon class="mr-2" :color="message.textColor">
            {{ message.icon }}
          </v-icon>
          <span :style="{ color: message.textColor }">
            {{ message.message }}
          </span>
        </div>
      </div>

      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="$emit('remove', message.id)">
          <v-icon :color="message.textColor">{{ mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  props: {
    messages: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      mdiClose,
    }
  },
}
</script>
