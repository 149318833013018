import Vue from 'vue'
import store from './store'
import router from './router'
import { setupPlausible } from './services/plausible'
import { isSPA } from '@/utils/env'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// Plugins
import vuetify, { setSystemTypeColors } from './plugins/vuetify'
import 'chartjs-plugin-datalabels'
import i18n from './plugins/i18n'

// Global application styling
import './assets/scss/theme.scss'

// Main application component
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

import mixpanel from 'mixpanel-browser'
mixpanel.init('16cbcb3f5c1fa279076e567395b2739f', { debug: true })

// https://vuejs.org/v2/api/#productionTip
Vue.config.productionTip = false

// Add plugins
setupPlausible()

function isSetupRoute() {
  return window.location.pathname === '/general-settings/heroku'
}

if (isSPA) {
  const AppDev = () => import('./AppDev.vue')

  new Vue({
    router,
    vuetify,
    render: (h) => h(AppDev),
  }).$mount('#appDev')
} else {
  document.getElementById('appDevContainer').remove()
}

if (process.env.VUE_APP_ENV === 'qa') {
  Sentry.init({
    Vue,
    dsn: 'https://b899e2df019f4bb7b38ff492ed972499@sentry.activeprime.io/2',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    environment: process.env.VUE_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

/**
 * Since there is no message type, we are using
 * property detection to initialize the application
 *
 * event.data has the following properties
 * - systemType
 * used to generate the jwt
 * - userId
 * - orgId
 * - permissionName
 * - sessionId
 * - email
 * - firstName
 * - lastName
 * - userName
 * - totp
 * - data.entityKey
 * - data.entityName
 */
if (isSetupRoute() && !isSPA) {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
} else {
  window.addEventListener('message', (event) => {
    if (event.data) {
      const { userId, systemType = 'sfdc' } = event.data

      if (userId) {
        setUpSystemTypeConfigurations(systemType)

        new Vue({
          router,
          store,
          vuetify,
          i18n,
          render: (h) =>
            h(App, {
              props: {
                context: isSetupRoute() ? null : event.data,
              },
            }),
        }).$mount('#app')
      }
    }
  })
}

function setUpSystemTypeConfigurations(systemType) {
  // data attribute used to apply different styles
  document.querySelector('html').setAttribute('systemType', systemType)
  setSystemTypeGlobalFlag(systemType)
  setSystemTypeColors(systemType)
}

function setSystemTypeGlobalFlag(systemType) {
  Vue.prototype.$sfdc = false
  Vue.prototype.$msdcrm = false
  Vue.prototype[`$${systemType}`] = true
}
