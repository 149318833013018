<template>
  <v-dialog
    v-if="value"
    :value="value"
    persistent
    scrollable
    :max-width="maxWidth"
    width="100%"
  >
    <div class="ap-card d-flex flex-column">
      <div class="d-flex flex-column px-6 pt-4 pb-2">
        <div class="d-flex flex-no-wrap justify-space-between">
          <ApLogo />

          <div v-if="isCloseIconVisible" class="d-flex align-center">
            <v-icon @click="closeDialog"> $mdi-close </v-icon>
          </div>
        </div>

        <div
          class="font-weight-medium text-h6"
          :class="{
            'text-center': isContentCentered,
            [titleColorClass]: !!titleColorClass,
          }"
        >
          <slot name="title">
            <div class="mt-4">
              {{ title }}
            </div>
          </slot>
        </div>
      </div>

      <v-divider v-if="isDividersVisible" />

      <div
        class="white ap-dark-gray--text"
        :class="{
          'text-center': isContentCentered,
          'pb-6': !isDividersVisible,
        }"
        :style="{ 'max-height': maxContentHeight }"
        style="position: relative; overflow-y: auto"
      >
        <slot name="content">
          <div class="px-6">
            <div v-for="(item, index) in contents" :key="index" class="mb-3">
              <slot :name="`content-${index}`">
                {{ item }}
              </slot>
            </div>
          </div>
        </slot>

        <slot name="append-content" />
      </div>

      <v-divider v-if="isDividersVisible" />

      <div
        v-if="actions.length > 0"
        class="d-flex flex-column px-6 pb-3 white"
        :class="{ 'pt-3': isDividersVisible }"
      >
        <div
          class="d-flex flex-wrap pb-0"
          :class="{
            'justify-center': isContentCentered,
            'justify-end': !isContentCentered,
          }"
        >
          <v-btn
            v-for="action in actions"
            :key="action.event"
            v-bind="{ ...action }"
            class="ml-2"
            @click="emitAction(action)"
          >
            <v-icon v-if="action.leftIcon" left>
              {{ action.leftIcon }}
            </v-icon>
            {{ action.label }}
            <v-icon v-if="action.rightIcon" right>
              {{ action.rightIcon }}
            </v-icon>
          </v-btn>
        </div>

        <slot name="append-actions" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApLogo from './ApLogo'

export default {
  components: {
    ApLogo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '600',
    },
    isCloseIconVisible: {
      type: Boolean,
      default: true,
    },
    isDividersVisible: {
      type: Boolean,
      deafult: false,
    },
    isContentCentered: {
      type: Boolean,
      default: false,
    },
    maxContentHeight: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleColorClass: {
      type: String,
      default: undefined,
    },
    content: {
      type: [String, Array],
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    contents() {
      return Array.isArray(this.content) ? this.content : [this.content]
    },
  },
  methods: {
    emitAction(action) {
      if (!action.href) {
        this.$emit(action.event)
        this.$emit('action', action.event)
      }
    },
    closeDialog() {
      this.$emit('input', false)
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped>
.ap-card div {
  background-color: white;
}
</style>
